var seedcoder = (function () {
	var timeLog_lastTime; //This will be our global var to record last time logged used for split time. Only global to seedcoder
	var timeLog = [];

	return {
		generateSplitTime: function (message, clearTime) {
			var startTime = new Date().getTime(),
				totalTime,
				duration,
				output;

			if (clearTime) {
				//Add up all durations
				totalTime = 0;

				for (var i = 0; i < timeLog.length; i++) {
					totalTime += timeLog[i];
				}

				duration = 0;
				output =
					message +
					'\nTotal time - ' +
					totalTime / 1000 +
					' seconds\n\nEnd: ' +
					new Date() +
					' ===============================================================================\n';

				//Clear duration array
				timeLog = [];
			} else if (timeLog_lastTime) {
				duration = startTime - timeLog_lastTime;
				output =
					parseFloat(duration / 1000).toFixed(3) + ' - ' + message;
			} else {
				duration = 0;
				output =
					'\nStart: ' +
					new Date() +
					' ===============================================================================\n\n' +
					message;
			}
			timeLog.push(duration);
			timeLog_lastTime = clearTime ? null : startTime;

			return output;
		},

		logSplitTime: function (message, clearTime) {
			// console.log(this.generateSplitTime(message, clearTime));
		},

		generateTestEvents: function (start, end, count, statuses, resources) {
			var events = [];
			var daySpan = end.diff(start, 'days'); // 1 day
			var eventsPerDay =
				count / daySpan < 1 ? 1 : Math.ceil(count / daySpan);
			var event;
			var date = moment(start).hour(0).minute(0);
			var allDay;
			var dayEventCounter = 0;

			for (var i = 0; i < count; i++) {
				if (dayEventCounter < eventsPerDay) {
					dayEventCounter++;
					if (dayEventCounter < 280) {
						date = moment(date).add(5, 'minutes');
						allDay = false;
					} else {
						allDay = true;
					}
				} else {
					dayEventCounter = 0;
					date = date.add(1, 'days').hour(0).minute(0);
					allDay = true;
				}

				event = {
					allDay: allDay,
					eventID: 0,
					title: 'This is loading for speed testing. This is a read only title for display',
					titleEdit:
						"This is for speed testing, let's see how fast this is.",
					start: date,
					end: moment(date).add(30, 'minutes'),
					description:
						'This is some additional event text that would go in the description area.',
					status: [
						statuses[Math.floor(Math.random() * statuses.length)]
							.name,
					],
					resource: [
						resources[Math.floor(Math.random() * resources.length)]
							.name,
					],
				};
				events.push(event);
			}
			return events;
		},
	};
})();
